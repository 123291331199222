<script>
    import TaskFile from "./file"
    import globalPersonMixin from "@/components/pessoa/globalPersonMixin"

    export default {
      name: 'task-attachments-files',
      mixins: [globalPersonMixin],
      props: {
        task: Object,
        comment: {
          type: Object,
          required: false
        },
        noComment: {
          type: Boolean,
          required: false
        },
        isGerencia: {
          type: Boolean,
          required: false
        }
      },
      data () {
        return {
          //files: MockFiles
        }
      },
      computed: {
        attachments () {
          if (!this.task || !this.task.attachments || !this.task.attachments.length) {
            return null
          }
          return this.task.attachments.filter(file => {
            if (this.noComment) {
              return !file.comment
            }
            return !this.comment || this.comment.id === file.comment
          }).map(file => {
            const fileinfo = file.originalFilename.split('.')
            const extension = fileinfo[fileinfo.length - 1]
            return {
              ...file,
              extension: extension
            }
          })
        }
      },
      methods: {},
      components: {TaskFile}
    }
</script>

<template>
    <div class="task-files m-b-lg">
        <ul>
            <li :key="file.id" v-for="file in attachments">
                <task-file :task="task" :file="file" :is-gerencia="isGerencia" /> <span class="desc">enviado {{file.createdAt|formatDate}} por {{firstLastName(file.createdByName)}}</span>
            </li>
        </ul>
    </div>
</template>
